exports.components = {
  "component---src-craft-dynamic-queries-lakota-article-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/ArticleQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-article-query-js" */),
  "component---src-craft-dynamic-queries-lakota-blog-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/BlogQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-blog-query-js" */),
  "component---src-craft-dynamic-queries-lakota-lakota-about-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/LakotaAboutQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-lakota-about-query-js" */),
  "component---src-craft-dynamic-queries-lakota-lakota-home-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/LakotaHomeQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-lakota-home-query-js" */),
  "component---src-craft-dynamic-queries-lakota-lakota-media-resources-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/LakotaMediaResourcesQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-lakota-media-resources-query-js" */),
  "component---src-craft-dynamic-queries-lakota-page-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/PageQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-page-query-js" */),
  "component---src-craft-dynamic-queries-lakota-resources-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/ResourcesQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-resources-query-js" */),
  "component---src-craft-dynamic-queries-lakota-video-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/VideoQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-video-query-js" */),
  "component---src-craft-dynamic-queries-lakota-writing-query-js": () => import("./../../../src/craft/dynamicQueries/lakota/WritingQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-lakota-writing-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

